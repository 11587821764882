
.button {
    display: inline-block;
    background-color: $mid-blue;
    padding: 20px 30px;
    margin: 0 0 20px 0;
    border-radius: 20px 10px 40px 10px;
    border-width: 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: white;
    text-align: center;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);

    &:hover {
        background-color: $dark-blue;
        text-decoration: none;
        color: white;
        cursor: pointer;
        transform: translate(0,-2px);
        box-shadow: 0 1px 2px rgba(0,0,0,0.04), 
        0 2px 4px rgba(0,0,0,0.04), 
        0 4px 8px rgba(0,0,0,0.04), 
        0 8px 16px rgba(0,0,0,0.04),
        0 16px 32px rgba(0,0,0,0.04), 
        0 32px 64px rgba(0,0,0,0.04);
    }

    &.primary {
        background-color: $mid-red;
        &:hover {
            background-color: $dark-red;
    
        }

        &.white {
            background-color:white;
            &:hover{
                background-color: white;
            }
        }
    
    }

    &.secondary {
        background-color: $mid-blue;
    }
    
    &.tertiary {
        background-color: $dark-blue;
    }

    &.ghost {
        border: 1px solid white;
        background: none;
    }

    &.full-width {
        width: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        font-size: 16px;
    }
}

.mat-button {
    color: $mid-red;
}

.socialButton {
    padding: 10px 10px 10px 40px;
    text-align: left;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    border: 0px solid $mid-blue;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width:550px) {
        margin-bottom: 10px;
    }

    &.google {
        background-image: url('../../assets/google.svg');
        background-color: white;
        border: 1px solid $light-blue;
        @include box-shadow;

        &:hover {
            box-shadow: none;
        }
    }
    
    &.facebook {
        background-image: url('../../assets/facebook.svg');
        background-color: #3B5998;
        color: white;
        box-shadow: 0 1px 1px rgb(30 35 55 / 10%), 0 2px 2px rgb(30 35 55 / 10%), 0 4px 4px rgb(30 35 55 / 10%), 0 6px 8px rgb(30 35 55 / 10%), 0 8px 16px rgb(30 35 55 / 10%);

        &:hover {
            box-shadow: none;
        }
    }

}