.stagePlaceholder {
    background-color: $dark-blue;
    height: 45vh;
    min-height: 500px;
    width: calc(65vw + 120px);
    margin-left: -120px;
    margin-bottom: 0px;

    @media screen and (max-width: 1279px) {
      height: 45vh;  
      margin-bottom: -55px;
      margin-left: 0px;
      min-height: 300px;
    }

}

