.slider {
    display: flex;
    flex-flow: row;
    overflow-x: scroll;
    padding: 0px 0;
    -ms-overflow-style: none; 
    overflow: -moz-scrollbars-none; 
    -webkit-overflow-scrolling: touch;
    padding: 0 25px;
    margin-right: -40px;
    margin-left: -40px;

    @media screen and (min-width:1280px) {
        margin-right: -75px;
        margin-left: -170px;
        padding: 0 155px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .col-*:last-child {
       padding-right: 155px; 
    }

}