.content {
    min-height: 100vh;
    padding: 50px 75px 75px 50px;
    flex-grow: 1;
    position: relative;
    width: 65vw;
    background-color: $light-blue;
    z-index: 10;

    .bg-light-blue {
        margin: 0 -75px 40px -170px;
        padding: 40px 75px 40px 170px; 
    }

    @media screen and (min-width:1280px) {    
        box-shadow: -120px 0 0 $light-blue;
    }

    @media screen and (min-width:1025px) and (max-width:1279px) {    
        // position: absolute;
        // top:150px;
        // left: 0;
        // right: 0;
        width: 100vw;
    }

    @media screen and (max-width:1024px) {
        padding: 0px 40px 100px 40px;
        margin-top: 65px;
        width: 100%;

        &::before {
            display: block;
            content: '';
            position: absolute;
            height: 200px;
            transform: rotate(-3deg) translateZ(0);
            // transform: rotate(-3deg);
            left: -2px;
            width: 5000px;
            top: -180px;
            background-color: $light-blue;
            border-top-left-radius: 60px;
            z-index: -1;
            box-shadow: 0 0 1px 0 $light-blue;
        }

        .bg-light-blue {
            margin: 0 -40px 40px -40px;
            padding: 40px;
        }

    }

    .container-fluid {
        max-width: 1200px;
    }
    
    .placeholder {
        width: 100%;
        height: 150px;
        background-color: $dark-blue;
        margin-bottom: 20px;
    }

}

.supPageContent {
    min-height: 100vh;
    padding: 100px 75px 75px 50px;
    flex-grow: 1;
    position: relative;
    width: 55vw;
    background-color: $light-blue;
    z-index: 10;

    .bg-light-blue {
        margin: 0 -75px 40px -170px;
        padding: 40px 75px 40px 170px; 
    }

    @media screen and (max-width:1023px) {
        padding: 0px 40px 100px 40px;
        margin-top: 65px;
        width: 100%;

        &::before {
            display: block;
            content: '';
            position: absolute;
            height: 200px;
            transform: rotate(-3deg);
            left: -2px;
            width: 5000px;
            top: -180px;
            background-color: $light-blue;
            border-top-left-radius: 60px;
        }

        .bg-light-blue {
            margin: 0 -40px 40px -40px;
            padding: 40px;
        }

    }


    .placeholder {
        width: 100%;
        height: 150px;
        background-color: $dark-blue;
        margin-bottom: 20px;
    }

}


.contentColumn {
    flex-grow: 1;
    @media screen and (min-width:1280px) {    
        margin-left: 35vw;
        box-shadow: -120px 0 0 $light-blue;
    }
}

.supContentColumn {
    flex-grow: 1;
    // overflow-y: scroll;
    // height: 100vh;
    // -webkit-overflow-scrolling: touch;
    @media screen and (min-width:1280px) {    
        margin-left: 35vw;
        box-shadow: -120px 0 0 $light-blue;

        .content {
            box-shadow: -120px 0 0 $light-blue;
        }

    }

}



.detailContentColumn {
    flex-grow: 1;
    // overflow-y: scroll;
    // height: 100vh;
    // -webkit-overflow-scrolling: touch;
    @media screen and (min-width:1280px) {    
        margin-left: 45vw;

        .content {
            // box-shadow: -120px 0 0 $light-blue;
        }

    }



}


.collpased {

    @media screen and (max-width:1024px){
        height: 145px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        position: relative;
    }
}

.collapseLink {
    color: $mid-red;
    @media screen and (min-width:1025px){
        display: none;
    }
}

.hide {
    display: none;
}

.subHeader {
    display: block;
    padding: 0 25px 10px 25px;
    
    @media screen and (min-width:681px) {    
        box-shadow: -120px 0 0 $mid-blue;
        background-color: $mid-blue;
        padding: 80px 50px;

        h3 {
            font-size: 48px;
        }

    }
}

.divider {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 0 15px 0;
    text-align: center;

    .inner {
        position: relative;
        padding: 0 10px;
        z-index: 1;
        color: $mid-blue;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 49%;
        left: 0px;
        width: 40%;
        height: 1px;
        background: $mid-blue;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 49%;
        right: 0;
        width: 40%;
        height: 1px;
        background: $mid-blue;
    }

}








#DetailPage {
    display: flex;

    @media screen and (min-width:1025px) {
        flex-direction: row;
    }

    @media screen and (max-width:1024px) {
        flex-direction: column;
        overflow: hidden;
    }

    .stage {
        top: 0;
        background-color: $dark-blue;
        color: white;
        z-index: 0;
        @media screen and (min-width:1025px) {
            width: 40vw;
            min-width: 40vw;
            height: 100vh;
            position: sticky;
        }
        @media screen and (max-width:1024px){
            width: 100vw;
            height: 50vh;
            min-height: 450px;
            position: relative;
        }

        img {
            opacity: 0.8;
            object-fit: cover;
            object-position: center center;
            width: 100%;
            height: 100%;
        }

        h1 {
            font-weight: 900;
            margin-bottom: 10px;    
            text-shadow: 0px 3px 15px $dark-blue;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;    
            width: 100%;

            @media screen and (min-width:1025px) {
                font-size: 60px;
            }
    
            @media screen and (min-width:481px) and (max-width:1024px){
                font-size: 60px;

            }

            @media screen and (max-width:480px) {
                font-size: 32px;
            }
        }
    
        .inner {
            position: absolute;
            left: 0px;
            right: 15px;
            z-index: 10;
            color: white;
    
            .mat-icon.small {
                font-size: 20px;
                width: 20px;
            }
            
            @media screen and (min-width:1025px) {
                left: 50px;
                right: 50px;
                bottom: 75px;
            }
            @media screen and (min-width:481px) and (max-width:1024px){
                left: 40px;
                bottom: 100px;
            }
            @media screen and (max-width:480px) {
                left: 40px;
                bottom: 80px;
            }
        }

        &::after {
            content: '';
            @include layer();
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            @media screen and (min-width:1025px) {
                height: 80%;
            }
    
            @media screen and (max-width:1024px) {
                height: 100%;
            }
        }

    }

    .content {        
        @media screen and (max-width:1024px) {

        padding: 0px;
        margin-top: 0px;
        }
    }

    .contentColumnX {
        background-color: $light-blue;
        flex-grow: 1;
        position: relative;
        z-index: 1;
        width: 60vw;

        @media screen and (min-width:1025px) {
            padding:  50px 75px;        
            width: 60vw;
        }

        @media screen and (max-width:1024px) {
            padding: 0 40px 100px 40px;
            width: 100vw;

            &::before {
                display: block;
                content: '';
                position: absolute;
                height: 200px;
                transform: rotate(-3deg) translateZ(0);
                left: 0px;
                width: 1200px;
                top: -75px;
                z-index: -1;
                background-color: $light-blue;
                border-top-left-radius: 60px;
            }
        }
    }

}

.metaInformations {
    background-color: white;
    padding: 75px;
}


.eventCoundBadge {
    background:  $mid-blue-20;
    border-radius: 6px;
    white-space: nowrap;
    padding: 0px 5px;
    margin: 2px 0 0 0;
    display: inline-block;
}

.discountBadge {
    background-color: $mid-yellow;
    padding: 10px;
    border-radius: 25px;
    color: $ultra-dark-red;
    font-family: "Fira", sans-serif;
    font-size: 16px;
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;

    &::before {
        position: absolute;
        content: "";
        bottom: -7px;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-left: -10px;
        background-color: $mid-yellow;
        transform: rotate(45deg);
    }

     

}