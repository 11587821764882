.warning {
    background-color: $mid-red-20;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.info {
    background-color: $mid-blue-10;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.success {
    background-color: $mid-green-10;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
}
