/* Importing Bootstrap SCSS file. */
@import './../../node_modules/bootstrap/scss/bootstrap.scss';

@import 'partials/colors.scss';
@import 'partials/fonts.scss';
@import 'partials/content.scss';
@import 'partials/placeholder.scss';
@import 'partials/buttons.scss';
@import 'partials/slider.scss';
@import 'partials/tiles.scss';
@import 'partials/modals.scss';
@import 'partials/notifications.scss';
@import 'partials/mapbox-gl.scss';



html, body {
    background-color: $dark-blue;
    color: $dark-blue; 
    font-family: "Fira", sans-serif; 
    font-weight: 400;
    height: 100%; 
}

body { margin: 0; }

.mat-bottom-sheet-container{
    border-radius: 30px 30px 0 0;
}

.cdk-overlay-dark-backdrop {
    background-color: rgba(30,35,55,.85); 
    backdrop-filter: blur(4px);
    -webkit-overflow-scrolling: touch;  
}

.cdk-global-overlay-wrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;  
    // padding-top: 30px;
    // padding-bottom: 30px;
}

.mat-list-item-content {
    width: 100%;
}
.mat-list-item+.mat-list-item {
    border-top: 1px solid $light-blue;
}


.right {
    float: right;
}

.left {
    float: left;
}

.vCenter {
    display: flex;
    align-items: center;
}

.mb-20 {
    margin-bottom: 8rem;
}

.hidden-md-up {
    @media screen and (min-width: 481px) {
        display: none !important;
    }
}

.hidden-xs {
    @media screen and (max-width: 480px) {
        display: none !important;
    }
}

.hidden-lg-down {
    @media screen and (max-width: 1279px) {
        display: none !important;
    }
}

.hidden-lg-up {
    @media screen and (min-width: 1280px) {
        display: none !important;
    }
}


mat-icon {
    &.yellow {
        color: $mid-yellow;
    }
    &.red {
        color: $mid-red;
    }
}

    #ActionBoxTab {

        .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
            background: white;
            height: 3px;
        } 


        .mat-tab-body-wrapper {
            background-color: $dark-red;
            flex: 1 1 auto;
            padding: 0 15px;
        
            .mat-flat-button {
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }
        
        .mat-tab-label {
            opacity: 1!important;
            &.mat-tab-label-active {
                opacity: 1 !important;
            }
            &.mat-tab-disabled {
                opacity: 0.3 !important;
            }
        }
        @media screen and (max-width: 480px) {

        
        .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
          display: none !important; 
        }
        
        .mat-tab-group  {
            padding-top: 15px;
          margin-bottom: 20px;
          flex: 1 1 auto;
        //   margin-left: -30px;
        //   margin-right: -20px;
        }
        
        .mat-tab-label-container {
          display: flex;
          flex-grow: 1;
          overflow: hidden;
          z-index: 1;
          overflow-x: scroll !important;
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent; /* make scrollbar transparent */
            }
        }
        
        ::ng-deep .mat-tab-header-pagination {
          display: none !important;
        } 
        
        ::ng-deep .mat-tab-header {
            overflow-x: scroll !important;
        
        }
        
          ::ng-deep .mat-tab-label-container { 
            overflow: visible !important;
          }
        
        ::ng-deep .mat-tab-label-container {
        overflow: visible !important;
        }
        
        ::ng-deep .mat-tab-header::-webkit-scrollbar {
        display: none;
        }
        

    }
        
}


::ng-deep .mat-tab-body.mat-tab-body-active {
    z-index: 1000 !important;
}


.full-width {
    width: 100%;
}
mat-icon {
    vertical-align: middle;
    &.small {
        font-size: 20px;
    }
}

.mat-chip-list-wrapper input.mat-input-element, .mat-chip-list-wrapper .mat-standard-chip, .mat-chip.mat-standard-chip {
    background-color: $mid-blue-20-solid;
    color: $dark-blue;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
    background-color: $mid-red;
}

#citySelectBox {
    .mat-form-field-label {
        color: $mid-blue !important;

    }
    .mat-select-value {
        color: white !important;
    }
}

#musicIntroSearchBox {
    .mat-form-field-label {
        color: $mid-blue !important;

    }
    .mat-select-value {
        color: white !important;
    }
}

#SearchForm {


    // .ais-SearchBox {
    //     position: fixed;
    //     top: 50px;
    //     left: 250px;
    //     right: 200px;
    //     z-index: 20;

    //     @media screen and (max-width: 1279px) {
    //         top: 5px;
    //         left: 60px;
    //         right: 15px;
    //     }

    // }


    .ais-SearchBox-form {
        border: 1px solid $mid-blue-20;
        border-radius: 30px;
        padding: 0 5px 0 50px;
        height: 50px;
        transition: all 0.2s ease-out;
        box-shadow: rgb(30 35 55 / 7%) 0px 1px 4px, rgb(30 35 55 / 10%) 0px 0px 1px;
        display: flex;
        align-items: center;
        background-color: white;
        position: relative;

        .ais-SearchBox-submit, .ais-SearchBox-reset {
            background:none;
            border:none;
        }

        .ais-SearchBox-submit {
            position: absolute;
            left: 25px;

            .ais-SearchBox-submitIcon {
                width: 20px;
            }
        }

        .ais-SearchBox-reset {
            position: absolute;
            right: 15px;

            .ais-SearchBox-resetIcon{
                width: 14px;
            }
        }
        .ais-SearchBox-input {
            padding-left: 8px;
        }

        input {
            border: none;
            padding-right: 35px;
            outline: none;
            width:100%;
            transition: all 0.2s ease-out;

            
        }

        // .deleteButton {
        //     position: absolute !important;
        //     right: 5px;
        //     color: $mid-blue-60;
        // }

        // &:focus, &:hover {
        //     box-shadow:rgb(30 35 55 / 8%) 0px 3px 15px, rgb(30 35 55 / 9%) 0px 1px 3px;
        //     outline: none;
        //     background-color: white;
        // }


    }

    .ais-Hits {
        // @media screen and (max-width:550px) {
        //     width: calc(90vw - 30px);
        // }
    }

    .ais-Hits-list, .ais-InfiniteHits-list, .ais-InfiniteResults-list, .ais-Results-list {
        margin-top: 0;
        margin-left: 0;
    }

    .ais-Hits-item {

        width: 100%;
        .hit-name {
            width: 100%;
            display: inline-block;
        }

    }


}



.mapboxgl-popup-content {
    padding: 30px 30px 10px 30px;
    border-radius: 20px 10px 40px 10px;

    h2 {
        font-size: 24px;
        margin-bottom: 10px;
        margin-right: 50px;
    }

    .button:focus-visible {
        outline: none;
    }

}

@media screen and (max-width: 1279px) {
    .mapboxgl-map .mapboxgl-popup{
        right:0;
        bottom:0;
        top:auto;
        left:0;
        position:fixed; 
        width: 100%;
        height: 30vh;
        background-color: white;
        transform:none !important;
        z-index: 5000;    
        border-radius: 30px 30px 0px 0px;
    }
    
    .mapboxgl-popup-content {
        position: relative;
        background: none;
        box-shadow: none;
        padding: 20px 20px 0px 20px;
        pointer-events: auto;

        h2 {
            font-size: 24px;
            margin-bottom: 0;
            margin-right: 35px;
        }
    }
    
    .mapboxgl-map .mapboxgl-popup-tip{display:none;}
    

}

.mapboxgl-popup-close-button {
    width: 36px;
    height: 36px;
    font-size: 36px;
    color: $dark-blue;
    top: 16px;
    right: 16px;
}

.mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
    background-color: white;
}

.mat-slider.mat-accent .mat-slider-track-fill, .mat-slider.mat-accent .mat-slider-thumb, .mat-slider.mat-accent .mat-slider-thumb-label {
    background-color: white;
}

input::-webkit-date-and-time-value {
    text-align: left;
}

#AccountSubNavigation .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
    border-radius: 4px;
}