@import 'colors.scss';


.tile {
    border: 1px solid $mid-blue-20;
    border-radius: 5px;
    padding: 15px;
    margin: 0 0 20px 0;

    &.dashed {
        border: 1px dashed $mid-blue-20;
    }

    &.boxShadow {
        @include box-shadow-soft;
    }

    &.selected {
       border: 1px solid $mid-red;
       @include box-shadow-soft;
    }

}



.floatingTile {
    position: relative;
    max-width: 550px;
    background: white;
    padding: 50px 30px 20px 30px;
    border-radius: 35px 15px 70px 15px;
    border: 5px solid rgba(255,255,255,0.5);
    margin: 30px auto 30px auto;
    color: $dark-blue;
    @include box-shadow;

    h1 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 900;
    }

    .mat-raised-button { border-radius: 15px 10px 40px 10px !important;}
    .mat-stroked-button {margin-bottom: 15px;}
    &::before {
        content: '';
        width: 640px;
        height: 718px;
        // background-image: url('../../../assets/lanyard.svg');
        display: block;
        position: absolute;

        
        top: -690px;
        left: 50%;
        margin-left: -320px;
    }

    // &::after {
    //     display: block;
    //     content: '';
    //     background: -moz-linear-gradient(35deg,rgba(255,255,255,0.1) 18%, rgba(255,255,255,0.02)  100%);
    //     background: -webkit-linear-gradient(35deg,  rgba(255,255,255,0.1) 18%, rgba(255,255,255,0.02)  100%);
    //     background: linear-gradient(35deg,  rgba(255,255,255,0.1) 18%, rgba(255,255,255,0.02)  100%);
    //     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=#{rgba(255,255,255,0.5)}, endColorstr=#{rgba(255,255,255,0.2)},GradientType=1 );
    //     position: absolute;
    //     top:-15px;
    //     left: -15px;
    //     right: -15px;
    //     bottom: -15px;
    //     border-radius: 40px 20px 90px 20px;
    //     z-index: -1;
    //     -moz-box-shadow: 0 4px 18px 0 rgba(0,0,0,0.08),inset 0px 2px 20px rgba(255,255,255,0.05);
    //     -webkit-box-shadow: 0 4px 18px 0 rgba(0,0,0,0.08),inset 0px 2px 20px rgba(255,255,255,0.05);
    //     box-shadow: 0 4px 18px 0 rgba(0,0,0,0.08),inset 0px 2px 20px rgba(255,255,255,0.05);
    
    // }


}

.modal-layout-new h1 {
    font-size: 24px;
    font-weight: 900;
}
