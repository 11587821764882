/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
  
/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
/* latin-ext */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/firasans/v16/va9E4kDNxMZdWfMOD5VvmYjLazX3dGTP.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/firasans/v16/va9E4kDNxMZdWfMOD5Vvl4jLazX3dA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  

h1 {
    font-size: 65px;
    font-weight: 600;
    margin-bottom: 20px;
    @media screen  and (min-width: 481px) and (max-width: 991px) {
        font-size: 60px;        
        line-height: 60px;
    }

    @media screen and (max-width: 480px) {
        font-size: 40px;        
        line-height: 40px;
    }
  
    
}

h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    
    .red {
        color: $mid-red;
    }
}

h3 {
    font-size: 24px;
    color: $mid-blue;
    font-weight: 600;
}

h4 {
    font-size: 16px;
    color: $mid-blue;
    font-weight: 400;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }  
}

p {
    font-size: 18px;
    letter-spacing: 0.3px;
    margin-bottom: 10px;
    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }  
}

.legal {
    color: $mid-blue-60;
    font-size: 14px;

}

.center {
    text-align: center;
}

.right {
    text-align: right;
}


.right-md {         
     @media screen and (min-width: 481px) {
        text-align: right;
    }  
}

.center-md {
    @media screen and (min-width: 481px) {
        text-align: center;
    }  
 }

.center-xs {
    @media screen and (max-width: 480px) {
        text-align: center;
    }  
}

.right-xs {         
    @media screen and (max-width: 480px) {
       text-align: right;
   }  
}


.textlink {
    color: $mid-red;
    text-decoration: none;
    display: inline-block;
    &:hover {    
        color: $mid-red;
        text-decoration: underline;
        cursor: pointer;
    }
}

.underline {
    text-decoration: underline;
}

.crossline {
    display: inline-block;
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $mid-red;
        position: absolute;
        top: 10px;
        transform: rotate(-2deg);
    }
}