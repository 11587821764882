//  BLUE

$dark-blue :        rgb(30,35,55);
$dark-blue-10 :     rgba(30,35,55,0.1);
$dark-blue-20 :     rgba(30,35,55,0.2);
$dark-blue-70 :     rgba(30,35,55,0.7);
$dark-blue-90 :     rgba(30,35,55,0.9);
$mid-blue :         rgb(125,140,165);
$mid-blue-60 :      rgba(125,140,165,0.6);
$mid-blue-20 :      rgba(125,140,165,0.2);
$mid-blue-20-solid :rgba(220,223,229);
$mid-blue-10 :      rgba(125,140,165,0.1);
$mid-blue-5 :       rgba(125,140,165,0.05);
$light-blue :       rgb(244,244,245);


//  RED

$ultra-dark-red :   rgb(210, 30, 65);
$dark-red :         rgb(225, 35, 70);
$dark-red-30 :      rgba(225,35,70,1);
$mid-red :          rgb(245,40,80);
$mid-red-90 :       rgba(245,40,80,0.9);
$mid-red-20 :       rgba(245,40,80,0.2);
$light-red :        rgb(253, 78, 113);


//  YELLOW

$mid-yellow :       rgb(255,190,0);


//  GREEN
$mid-green-10 :      rgba(75,170,75,0.1);


//  BACKGROUNDS

@mixin bg-light-blue() {
    background-color: $mid-blue-10;
}

@mixin bg-mid-red() {
    background-color: $mid-red;
}


@mixin gradient() {
    background: -moz-linear-gradient(15deg,  $dark-red 18%,  $mid-red 100%);
    background: -webkit-linear-gradient(15deg,  $dark-red 18%, $mid-red 100%);
    background: linear-gradient(15deg,  $dark-red 18%, $mid-red 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=#{$dark-red}, endColorstr=#{$mid-red},GradientType=1 );
}

@mixin layer() {
    background: -moz-linear-gradient(7deg,  rgba(30,35,55,0.85) 35%,  rgba(30,35,55,0.01) 60%);
    background: -webkit-linear-gradient(7deg,  rgba(30,35,55,0.85) 35%, rgba(30,35,55,0.01) 60%);
    background: linear-gradient(7deg,  rgba(30,35,55,0.85) 35%, rgba(30,35,55,0.01) 60%);
}

@mixin box-shadow {
   box-shadow: 0 1px 1px rgba(30,35,55,0.03), 
    0 2px 2px rgba(30,35,55,0.03), 
    0 4px 4px rgba(30,35,55,0.03), 
    0 6px 8px rgba(30,35,55,0.03),
    0 8px 16px rgba(30,35,55,0.03);
}

@mixin box-shadow-hard {
    box-shadow: 0 1px 1px rgba(30,35,55,0.23), 
     0 2px 2px rgba(30,35,55,0.23), 
     0 4px 4px rgba(30,35,55,0.23), 
     0 6px 8px rgba(30,35,55,0.23),
     0 8px 16px rgba(30,35,55,0.23);
 }

@mixin box-shadow-soft {
    box-shadow: 0 1px 1px rgba(30,35,55,0.03), 
     0 2px 2px rgba(30,35,55,0.03), 
     0 3px 3px rgba(30,35,55,0.03), 
     0 4px 7px rgba(30,35,55,0.03),
     0 5px 9px rgba(30,35,55,0.03);
 }
 
.bg-gradient {
    @include gradient();
}


.bg-light-blue {
    @include bg-light-blue();
}

.bg-mid-red {
    @include bg-mid-red();
}

.bg-white {
    background-color: white;
}

.white {
    color: white;
}

.lightBlue {
    color: $mid-blue;
}

.darkGrey {
    color: rgba(0,0,0,0.4);
}

.midRed {
    color: $mid-red;
}

.yellow {
    color: $mid-yellow;
}