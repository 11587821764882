#modal {
    background-color: $light-blue;
    padding: 30px 0;

    @media screen and (max-width:480px) {
        width: 100vw;
    }

}

#centerBox {
    padding: 50px 30px;



    @media screen and (max-width:480px) {
        width: 100vw;

        .button {
            position: fixed;
            bottom: 20px;
            left: 30px;
            right: 30px;
            width: auto;
            margin: 0px;
        }
    }

}